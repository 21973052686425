import React, { useState, useEffect} from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import SendIcon from '@material-ui/icons/Send';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

const filterOptions = createFilterOptions({
  limit:1
});

export default function AnswerSuggestion(props) {
    const [value, setValue] = useState([]);
    const [text, setText] = useState("");
    const [options, setOptions] = useState([{id:1, answer:"cool beans"}]);
    const effectiveOptions = (text.length === 0) ? [] : (value.length === 0) ? options : value;
    const label = value.length === 0 ? props.label || "Answer Here": "";
    const variant = props.variant || "outlined";
    useEffect(() => {
        fetch("/api/answer?query=".concat(text))
            .then(res => res.json()) // todo fix the bug if the response isn't json parsable
                .then(
                    ({options}) => {
                        setOptions(options);
                    });
    }, [text]);
    const submit = () => {
        if (value.length > 0){
            const obj = {question_id: props.question.id};
            const options = { method: "PUT", headers: { 'Content-Type': "application/json"}, body: JSON.stringify(obj)};
            fetch("/api/answer/".concat(value[0].id), options)
            .then(res => res.json()) // todo fix the bug if the response isn't json parsable
                .then(
                    () => {
                        setValue([]);
                    });
        }
    };
    const onKeyUp = (e) => {
        if (e.keyCode === 13){
            submit();
        }
    }
    return (
      <Autocomplete
        multiple
        freeSolo
        id="AnswerSuggest"
        noOptionsText="No Matches"
        filterOptions={filterOptions}
        value={value}
        inputValue={text}
        onInputChange={(e) => { const newText = (e.target.value === 0) ? "" : e.target.value; return (value.length === 0) ? setText(newText): ""} }
        onChange={(event, newValue) => {
          setValue([
            ...newValue
          ]);
        }}
        options={effectiveOptions}
        getOptionLabel={(option) => option.answer}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.answer}
              {...getTagProps({ index })}
            />
          ))
        }
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField {...params} variant={variant} placeholder={label} onKeyUp={onKeyUp}
            InputProps={{ ...params.InputProps, type: 'search',  endAdornment: <InputAdornment onClick={submit} position="end"><a href="#"><SendIcon /></a> </InputAdornment> }}

            />
        )}
      />
  );
}
