import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Homepage from './Homepage';
import Create from './Create';
import Join from './Join';
import Game from './Game';

function App() {
  return (
      <Router>
        <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path="/create" exact component={Create} />
            <Route path="/join" exact component={Join} />
            <Route path="/:gameId" component={Game} />
        </Switch>
      </Router>
  );
}

export default App;
