import React, { useRef, useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AnswerSuggestion from './components/AnswerSuggestion';



function Question(props){
    var answersList = [];
    const timing = useRef({start: new Date(), expiry: new Date(props.room.question.closed_at*1000)});
    const [progress, setProgress] = useState(0);

    const calculateProgress = () => {
        // given time between start and expiry, what % of time is left?
        const now = new Date();
        const range = timing.current.expiry - timing.current.start;
	if (range < 0) { return 100;}
        const diff = now - timing.current.start;
        // imagine range = 30, diff = 5... 5/30*100
        setProgress(Math.round(Math.max(Math.min((diff/range)*100, 100), 0)));


    };
    useEffect(() => {const timer = setInterval(calculateProgress, 10); return () => clearInterval(timer);}, []);
    useEffect(() => {
        const newStart = new Date();
        timing.current.start = newStart;
        timing.current.expiry = new Date(props.room.question.closed_at*1000);
        calculateProgress();
    }, [props.room.question.closed_at]);

    if (props.room.question.answers.length > 0){
        for (const answer of props.room.question.answers){
            answersList.push(<ListItem key={answer.answer}>
                <ListItemText primary={answer.answer} />
            </ListItem>);
            answersList.push(<Divider key={"divider-".concat(answer.answer)} />);
        }
        answersList.push(<ListItem key={"input"}><AnswerSuggestion variant="standard" question={props.room.question} /></ListItem>);

    }
    const answers = <List>{answersList}</List>;
    var response = [];
    if (answersList.length === 0){
        response = <AnswerSuggestion question={props.room.question} />;
    }

    return <div>
        <p> Round {props.room.question.round_number} </p>
        <LinearProgress variant="determinate" value={Math.round((props.room.question.question_number / props.room.qs_per_round)*100)} />
        <p> {props.room.question.question_number} / {props.room.qs_per_round} </p>
        <CircularProgress variant="static" value={progress} />
	{progress}
        <h5> {props.room.question.category} </h5>
        <h4> {props.room.question.question} </h4>
        {answers}
        {response}

    </div>;
}


export default Question

