import React, { useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import './App.css';
import Header from './components/Header';

function Join(props){
    const [gameId, setGameId] = useState("");
    const changeGameId = (event) => {
        setGameId(event.target.value);
    }
    const joinGame = () => {
        props.history.push("/".concat(gameId));
    }
    const buttonDisabled = gameId.length !== 6;
    return (
        <div>
        <Header />
        <TextField label={"Game ID"} variant="outlined" onChange={changeGameId}  />
        <Button onClick={joinGame} disabled={buttonDisabled} > Join! </Button>
        </div>
    );
}

export default Join
