import React, { useState, useEffect} from 'react';
import Lobby from './Lobby';
import JoinGame from './JoinGame';
import Question from './Question';
import RoundReview from './RoundReview';
import TriviaSocket from './components/TriviaSocket';




function Game(props){
    const [roomInfo, setGameInfo] = useState({});
    const [meInfo, setMeInfo] = useState();
    //const client = new WebSocket(window.location.origin.concat("/websocket").replace("https", "wss").replace("http", "wss"));
    useEffect(() => {
        // TODO find out if the user is already logged in, jump them to the game window if so...
        fetch("/api/room/".concat(props.match.params.gameId))
            .then(res => res.json()) // todo fix the bug if the response isn't json parsable
                .then(
                    ({me, room}) => {
                        setGameInfo(room);
                        setMeInfo(me);
                    });
    }, [props.match.params.gameId]);
    const cb = ({me, room}) => {
	if (room !== undefined){
            setGameInfo(room);
	}
	if (me !== undefined){
            setMeInfo(me);
	}
    }
    const socketCB = (room) => {
        setGameInfo(room);
    }
    const meDefined = () => {
        return !(meInfo === null || meInfo === undefined) && meInfo.id !== undefined;
    }
    const socket = <TriviaSocket cb={socketCB} started={roomInfo.started} />;
    const renderer = () => {
        if (meDefined() && roomInfo.started){
            if (roomInfo.round_review !== undefined){
                return <div><RoundReview round_review={roomInfo.round_review} /> {socket} </div>;
            } else {
                return <div><Question room={roomInfo} me={meInfo} /> {socket} </div>;
            }
        }
        else if (meDefined()){
            return <div><Lobby room={roomInfo} me={meInfo} cb={cb} /> {socket} </div>;
        } else {
            return <JoinGame room={roomInfo} cb={cb} />;
        }
    }
    return <div>
            {renderer()}
        </div>
}


export default Game

