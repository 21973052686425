import React, { useState, useEffect} from 'react';
import socketIOClient from "socket.io-client";

export default function TriviaSocket(props){

    useEffect(() => {
        const socket = (window.location.host.startsWith("localhost")) ? null : socketIOClient();
        if (socket !== null){
            socket.on("message", data => {
                console.log(data);
                if (props.cb !== undefined){
                    props.cb(data);
                }
            });
        }
        return () => socket.close();
    }, [props.started]);

    return <div />;
}
