import React, { useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link } from "react-router-dom";
import './App.css';
import RightChevron from './img/rightChevron.svg';

import Header from './components/Header';

function Create(props){
    const roundMarks = [{value:3},{value:5},{value:7},{value:10}];
    const questionMarks = [{value:5},{value:10},{value:15},{value:20}];
    const [name, setName] = useState("");
    const [nameConfirmed, setNameConfirmed] = useState(false);
    const [rounds, setRounds] = useState(roundMarks[1]);
    const [numQuestionsPerRound, setQuestionsPerRound] = useState(questionMarks[1]);
    const [roomInfo, setRoomInfo] = useState({});

    const inputName = (event) => {
        setName(event.target.value);
    }
    const confirmName = () => {
        setNameConfirmed(true);
    }
    const changeRounds = (event, value) => {
        setRounds(value);
    }
    const changeQuestionsPerRound = (event, value) => {
        setQuestionsPerRound(value);
    }
    const confirmRoundInfo = () => {
        const obj = {room_name: name,
                     rounds: rounds.value || rounds,
                     qs_per_round: numQuestionsPerRound.value || numQuestionsPerRound,
                     host_name: "ben" // TODO make this real
                    };
        const options = { method: "POST", headers: { 'Content-Type': "application/json"}, body: JSON.stringify(obj)};
        fetch("/api/room", options)
                .then(res => res.json()) // todo fix the bug if the response isn't json parsable
                    .then(
                        ({room}) => {
                            setRoomInfo(room);
                        })
    }
    const chooseName =
        <div>
            <p> Name the game! </p>
            <TextField label="Game Name"
                onChange={inputName}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                                    <img alt="submit" onClick={confirmName} src={RightChevron} />
                                  </InputAdornment>
                }}
            />
            <br />
            <Link to="/join"> JOIN A GAME INSTEAD </Link>
        </div>;
    const chooseRounds =
        <div>
            <p> Number of Rounds </p>
            <Slider
                defaultValue={roundMarks[1].value}
                marks={roundMarks}
                aria-label={"Number of Rounds"}
                name={"Number of Rounds"}
                valueLabelDisplay="on"
                step={null}
                onChangeCommitted={changeRounds}
            />
            <p> Questions per Round </p>
            <Slider
                defaultValue={questionMarks[1].value}
                marks={questionMarks}
                aria-label={"Questions Per Round"}
                name={"Questions Per Round"}
                valueLabelDisplay="on"
                step={null}
                onChangeCommitted={changeQuestionsPerRound}
            />
            <Button onClick={confirmRoundInfo} > Confirm </Button>
        </div>;
    const shareRoom =
        <div>
            <p> Share this link with friends so they can join your game </p>
            <TextField variant="outlined" defaultValue={"Trivia.Expert/".concat(roomInfo.id)} disabled={true} />
        </div>
    const renderer = () =>{
        if (roomInfo.id !== undefined){
            return shareRoom;
        } else if (!nameConfirmed){
            return chooseName;
        } else {
            return chooseRounds;
        }
    }
    return (
        <div>
        <Header />
        {renderer()}
        </div>
    );
}

export default Create
