import React, {useState, useEffect} from 'react';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';


function RoundReview(props){
    const [currentQuestionNum, setCurrentQuestionNum] = useState(0);
    console.log(props.round_review);
    const nextQuestion = () => {
        console.log(currentQuestionNum);
        if (currentQuestionNum + 1 < props.round_review.length){
            setCurrentQuestionNum(currentQuestionNum+1);
        }
    };
    useEffect(() => {
        const timer = setInterval(nextQuestion, 1000*10);
        return () => clearInterval(timer);
    }, []);
    const currentQuestion = props.round_review[currentQuestionNum];
    const validAnswers = currentQuestion.valid_answers.map(x => {
        return <ListItem key={"user-".concat(x.answer)} >
            <ListItemText primary={x.answer} />
        </ListItem>
    });
    const teamAnswers = currentQuestion.answers.map(x => {
        return <ListItem key={"user-".concat(x.answer)} >
            <ListItemText primary={x.answer} />
        </ListItem>
    });

    return <div>
            <h1> Round Review </h1>
            <h2>
                {currentQuestion.question}
            </h2>
            <h3> Your team said ... </h3>
            <List>
                {teamAnswers}
            </List>
            <h3> The Correct answers were ... </h3>
            <List>
                {validAnswers}
            </List>
        </div>

}
export default RoundReview;
/*
[{"id":971,"room_id":"fa66e29c","question_id":39706,"round_number":1,"question_number":1,"closed_at":1595789851.015444,"closed":true,"question":"What's the oed","answers":[],"valid_answers":[{"id":25331,"answer":"Oxford english dictionary"}]},{"id":972,"room_id":"fa66e29c","question_id":36289,"round_number":1,"question_number":2,"closed_at":1595789881.04617,"closed":true,"question":"Which is the largest african bird of prey","answers":[],"valid_answers":[{"id":8827,"answer":"Lammergeyer"}]},{"id":973,"room_id":"fa66e29c","question_id":19530,"round_number":1,"question_number":3,"closed_at":1595789911.068918,"closed":true,"question":"What was the name of the submarine which sank the General Belgrano during the Falklands conflict","answers":[],"valid_answers":[{"id":14267,"answer":"Hms conqueror"}]},{"id":974,"room_id":"fa66e29c","question_id":33720,"round_number":1,"question_number":4,"closed_at":1595789941.088671,"closed":true,"question":"Who founded Methodism in 1738","answers":[{"id":39,"room_question_id":974,"answer_id":1283,"user_id":56,"answered_at":"2020-07-19 18:08:01","answer":"Prometheus"}],"valid_answers":[{"id":22232,"answer":"John Wesley"}]},{"id":975,"room_id":"fa66e29c","question_id":38608,"round_number":1,"question_number":5,"closed_at":1595789971.10918,"closed":true,"question":"Who wrote The Swiss Family Robinson","answers":[{"id":40,"room_question_id":975,"answer_id":6,"user_id":56,"answered_at":"2020-07-19 18:08:01","answer":"His telephone the telephone telephone"}],"valid_answers":[{"id":24772,"answer":"J r wyss"}]}]
*/

