import React, { useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Header from './components/Header';

function JoinGame(props){
    const [name, setName] = useState("");
    const numTeams = () =>{
        if (props.room.teams === undefined){
            return 0;
        }
        return props.room.teams.length;
    }
    const numPlayers = () =>{
        if (props.room.users === undefined){
            return 0;
        }
        return props.room.users.length;
    }
    const updateName = (event) => {
        setName(event.target.value);
    }
    const joinGameDisabled = name.length <= 1;
    const joinGame = () => {
        const obj = {room_id: props.room.id, name: name};
        const options = { method: "POST", headers: { 'Content-Type': "application/json"}, body: JSON.stringify(obj)};
        fetch("/api/room/join", options)
            .then(res => res.json()) // todo fix the bug if the response isn't json parsable
                .then(
                    (result) => {
                        if (props.cb){
                            props.cb(result);
                        }
                    });
    }
    const promptJoinGame = <div>
            <Header />
            <p> Would you like to join <b> {props.room.name} ?</b> </p>
            <br />
            <p> There are currently {numTeams()} teams with a total of {numPlayers()} players </p>
            <p> Please Enter your name </p>
            <TextField label="name" onChange={updateName} />
            <Button disabled={joinGameDisabled} onClick={joinGame} > Join {props.room.name} </Button>
        </div>;
    return promptJoinGame;
}


export default JoinGame

