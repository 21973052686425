import React, { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Header from './components/Header';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function Lobby(props){
    const classes = useStyles();
    const createTeam = () => {
        const obj = {team_name: props.me.name.concat("'s team")};
        const options = { method: "POST", headers: { 'Content-Type': "application/json"}, body: JSON.stringify(obj)};
        fetch("/api/team/create", options)
            .then(res => res.json()) // todo fix the bug if the response isn't json parsable
                .then(
                    (data) => {
                        if (props.cb){
                            props.cb(data);
                        }
                    });

    }
    const joinTeam = (team_id) => {
        const obj = {team_id:team_id};
        const options = { method: "PUT", headers: { 'Content-Type': "application/json"}, body: JSON.stringify(obj)};
        fetch("/api/team/join", options)
            .then(res => res.json()) // todo fix the bug if the response isn't json parsable
                .then(
                    (data) => {
                        if (props.cb){
                            props.cb(data);
                        }
                    });

    }
    const startGame = () => {
        const options = { method: "PUT"};
        fetch("/api/room/start", options)
            .then(res => res.json()) // todo fix the bug if the response isn't json parsable
                .then(
                    (data) => {
                        if (props.cb){
                            props.cb(data);
                        }
                    });
    }
    const teamList = [];
    if (props.room.teams.length > 0){
        for (const team of props.room.teams){
            teamList.push(<ListItem button onClick={() => joinTeam(team.id)} key={team.name}>
                <ListItemText primary={team.name} />
            </ListItem>);
            const teamUsers = props.room.users.filter(x => x.team_id === team.id).map(x => {
                return <ListItem key={"".concat(team.id).concat(x.name)}><ListItemText primary={x.name} /> </ListItem>;
            });
            teamList.push(<List component="div" className={classes.nested} disablePadding> {teamUsers} </List>)
            teamList.push(<Divider key={"divider-".concat(team.name)} />);
        }
    }
    const startGameButton = (props.me.is_host === true) ? <Button onClick={startGame}> Start Game! </Button> : [];
    const notOnTeam = <div>
                <Header />
                <p> Choose a team to play with </p>
                {teamList}
                <p> Or you can create a new team </p>
                <Button onClick={createTeam} startIcon={<AddIcon />} > CREATE TEAM </Button>
            </div>;
    const onTeam = <div>
                <Header />
                {teamList}
                {startGameButton}
            </div>;
    return (props.me.team_id === null) ? notOnTeam : onTeam;
}

export default Lobby
