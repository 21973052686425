import React, { useEffect} from 'react';
import Button from '@material-ui/core/Button';
import './App.css';
import Logo from './img/logo.svg';

function Homepage(props){
    useEffect(() => {
        fetch("/api/status")
            .then(res => res.json())
                .then(
                    (result) => {
                        // TODO
                    }
                );
    }, []);
    const createGame = () => {
        props.history.push("/create");
    }
    const joinGame = () => {
        props.history.push("/join");
    }
    return (
        <div>
        <img src={Logo} alt="trivia.expert logo" />
        <h1> trivia.expert </h1>
        <Button onClick={createGame} variant="contained" color="primary" > Create Game </Button>
        <Button onClick={joinGame} variant="contained" color="primary" > Join Game </Button>
        </div>
    );
}

export default Homepage
