import React from 'react';
import Logo from './../img/logo.svg';

function Header(props){
    return <div>
        <img src={Logo} alt="trivia.expert logo" />,
        <h3> Trivia.Expert </h3>
        </div>
}

export default Header
